<template>
    <div>
        <Banner></Banner>
        <div class="page-container-white">
            <div class="page-title-container content-container">
                <div class="page-title">
                    <p>产品优势</p>
                    <p>PRODUCT ADVANTAGE</p>
                </div>
                <div class="hxjs-body">
                    <div class="advantage_msg">
                        <img src="~@/assets/imgs/private/adv_jslx.png" alt="">
                        <p class="adv_title"><span>技术领先</span></p>
                        <p class="adv_det"><span>基于业界领先的TDNN识别技术和大规模双语数据和端到端技术，高品质的满足产品的各种需求。</span></p>
                    </div>
                    <div class="advantage_msg">
                        <img src="~@/assets/imgs/private/adv_ptdy.png" alt="">
                        <p class="adv_title"><span>平台多样</span></p>
                        <p class="adv_det"><span>支持Linux/Windows部署，提供高性能服务器方案和轻量级单机方案，一站式解决所有问题。</span></p>
                    </div>
                    <div class="advantage_msg">
                        <img src="~@/assets/imgs/private/adv_mxjz.png" alt="">
                        <p class="adv_title"><span>模型精准</span></p>
                        <p class="adv_det"><span>基于海量训练，提升模型精准性，支持热词翻译术语的配置，进一步提升模型的准确度。</span></p>
                    </div>
                    <div class="advantage_msg">
                        <img src="~@/assets/imgs/private/adv_hjaq.png" alt="">
                        <p class="adv_title"><span>环境安全</span></p>
                        <p class="adv_det"><span>支持国产化的芯片和操作系统，为用户数据的安全性提供了有效的保障。</span></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-container-grey">
            <div class="page-title-container content-container">
                <div class="page-title">
                    <p>产品功能</p>
                    <p>PRODUCT FUNCTION</p>
                </div>
                <div class="hy_cpgn_body">
                    <div class="fun_msg">
                        <img src="~@/assets/imgs/private/fun_yysb.jpg" alt="">
                        <div>
                            <p class="fun_title"><span>语音识别</span></p>
                            <p class="fun_det"><span>对实时语音信号进行转写，实现所听即所见，识别准确率高达98.5%。</span></p>
                        </div>
                    </div>
                    <div class="fun_msg">
                        <img src="~@/assets/imgs/private/fun_yyhc.jpg" alt="">
                        <div>
                            <p class="fun_title"><span>语音合成</span></p>
                            <p class="fun_det"><span>将文字转化为自然流畅的声音，支持音频参数、语种的配置修改。</span></p>
                        </div>
                    </div>
                    <div class="fun_msg">
                        <img src="~@/assets/imgs/private/fun_znfy.jpg" alt="">
                        <div>
                            <p class="fun_title"><span>智能翻译</span></p>
                            <p class="fun_det"><span>支持根据不同行业领域的专业词汇需求，可自定义热词、翻译术语、敏感词。</span></p>
                        </div>
                    </div>
                    <div class="fun_msg">
                        <img src="~@/assets/imgs/private/fun_swsb.jpg" alt="">
                        <div>
                            <p class="fun_title"><span>声纹识别</span></p>
                            <p class="fun_det"><span>在声纹库中查看声纹是否匹配，主要应用于鉴权场景。</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-container-white">
            <div class="page-title-container content-container">
                <div class="page-title">
                    <p>项目案例</p>
                    <p>PROJECT CASES</p>
                </div>
                <div class="hy_xmal_body">
                    <div class="swiper-container swiper" id="swiper2">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide s-slide-1">
                                <div class="case_name">
                                    <p><span>手机厂商</span></p>
                                    <p><span>国内一家大型手机厂商，产品远销全球各地</span></p>
                                </div>
                                <div class="case_details_hide">
                                    <p><span>某公司是国内一家大型手机厂商，产品远销全球各地。针对该公司手机语音翻译 App 等应用需求，慧言科技为其提供了多语种的移动端语音合成解决方案，并提供了定制化支持，在解决跨语种沟通的问题的同时，保障用户数据安全。</span></p>
                                </div>
                            </div>
                            <div class="swiper-slide  s-slide-2">
                                <div class="case_name">
                                    <p><span>互联网公司</span></p>
                                    <p><span>一家国际化的大型互联网公司，经营多元化的互联网业务</span></p>
                                </div>
                                <div class="case_details_hide">
                                    <p><span>某公司是一家国际化的大型互联网公司，经营多元化的互联网业务。慧言科技为该公司提供了多语种的移动端语音合成解决方案，支持其语音翻译项目落地，用于解决日常交流、商务、旅游等场景中的跨语种沟通问题。</span></p>
                                </div>
                            </div>
                            <div class="swiper-slide  s-slide-3">
                                <div class="case_name">
                                    <p><span>株式会社</span></p>
                                    <p><span>日本的一家AI企业，致力于通过智能语音交互实现和谐的人机交互</span></p>
                                </div>
                                <div class="case_details_hide">
                                    <p><span>株式会社 Harmonized Interactions 是日本的一家AI企业，致力于通过智能语音交互实现和谐的人机交互。慧言科技为其提供了日语语音合成系统私有化 部署，并面向“说话头(talking-head)”场景进行 深度优化定制。</span></p>
                                </div>
                            </div>
                            <div class="swiper-slide  s-slide-4">
                                <div class="case_name">
                                    <p><span>AI企业</span></p>
                                    <p><span>一家大型AI企业，致力于生产可流畅、自然沟通的机器人</span></p>
                                </div>
                                <div class="case_details_hide">
                                    <p><span>某公司是一家大型AI企业，致力于生产可流畅、自然沟通的机器人。针对客户的需求及使用场景，慧言科技为其定制了音素级别时间预测功能，并将前端处理封装为独立模块，便于用户对预处理数据进行人工干预，进一步提高语音合成的自然度。</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-button-prev" id="swiper_prev2"></div>
                    <div class="swiper-button-next" id="swiper_next2"></div>
                </div>
            </div>
        </div>
        <div class="page-container-white">
            <div class="page-title-container content-container">
                <div class="page-title">
                    <p>合作伙伴</p>
                    <p>COOPERATIVE PARTNER</p>
                </div>
                <div class="hzhb-body">
                    <div><img src="~@/assets/imgs/par_11.jpg" alt=""></div> <!--滴滴-->
                    <div><img src="~@/assets/imgs/par_12.jpg" alt=""></div> <!--阿里巴巴-->
                    <div><img src="~@/assets/imgs/par_13.jpg" alt=""></div> <!--阿里云-->
                    <div><img src="~@/assets/imgs/par_14.jpg" alt=""></div> <!--天大-->
                    <div><img src="~@/assets/imgs/par_15.jpg" alt=""></div> <!--软银-->

                    <div><img src="~@/assets/imgs/par_21.jpg" alt=""></div> <!--株式会社-->
                    <div><img src="~@/assets/imgs/par_22.jpg" alt=""></div> <!--恒银金融-->
                    <div><img src="~@/assets/imgs/par_23.jpg" alt=""></div> <!--三甲-->
                    <div><img src="~@/assets/imgs/par_24.jpg" alt=""></div> <!--云译-->
                    <div><img src="~@/assets/imgs/par_25.jpg" alt=""></div> <!--中汽-->

                    <div><img src="~@/assets/imgs/par_31.jpg" alt=""></div> <!--塔米-->
                    <div><img src="~@/assets/imgs/par_32.jpg" alt=""></div> <!--INN-->
                    <div><img src="~@/assets/imgs/par_33.jpg" alt=""></div> <!--津云-->
                    <div><img src="~@/assets/imgs/par_34.jpg" alt=""></div> <!--烽火-->
                    <div><img src="~@/assets/imgs/par_35.jpg" alt=""></div> <!--天津广电-->
                </div>
            </div>
        </div>
        <div class="page-container-grey">
            <div class="page-hzzx">
                <p><span>点击下方按钮，获得我们的专属支持</span></p>
                <div><button><a href="mailto:bd@huiyan-tech.com" target="_blank">合作咨询</a></button></div>
            </div>
        </div>
    </div>
</template>

<script>
import "swiper/swiper-bundle.min.css";
import Swiper from "swiper";
import Banner from './Banner.vue'
export default {
    name: 'privatization',
    components: {
        Banner
    },
    mounted () {
        this.$nextTick(() => {
            new Swiper("#swiper2", {
                slidesPerView: 4,
                spaceBetween: 30,
                slidesPerGroup: 4,
                // loop: true,
                // loopFillGroupWithBlank: true,
                navigation: {
                    nextEl: "#swiper_prev2",
                    prevEl: "#swiper_prev2",
                },
            });
        })
    }
}
</script>

<style lang="less" scoped>
@import '../assets/css/product.css';
.s-slide-1 {
    background: url('~@/assets/imgs/private/case_img1.jpg') no-repeat center center;
    background-size: 100% 100%;
}
.s-slide-2 {
    background: url('~@/assets/imgs/private/case_img2.jpg') no-repeat center center;
    background-size: 100% 100%;
}
.s-slide-3 {
    background: url('~@/assets/imgs/private/case_img3.jpg') no-repeat center center;
    background-size: 100% 100%;
}
.s-slide-4 {
    background: url('~@/assets/imgs/private/case_img4.jpg') no-repeat center center;
    background-size: 100% 100%;
}
</style>